// Variables

// Main color
$primary-color: #FFF;

// Anchor color
$secondary-color: #9d85ae;

// Font color
$font-color: #433250;

$navbar-text-shadow: false;
$header-text-shadow: false;
$header-background-color: $primary-color;
$intro-text-color: $font-color;
$navbar-color: $font-color;
$navbar-lg-collapse-background-color: $header-background-color;
$navbar-lg-background: rgba(255, 255, 255, 1.0);
$navbar-sm-background-color: $navbar-lg-collapse-background-color;

$social-buttons-color: $secondary-color;
